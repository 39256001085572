<template>
  <div class="pagination">
    <el-button
      class="pagination__nav"
      @click="prev(prevDate)"
      :disabled="isDisabledPrev"
    >
      <img src="@/assets/icon/chevron-left.svg" alt="prev" />
    </el-button>
    <div class="pagination__btn" id="pagination__btn">
      <div
        v-for="(item, index) in props.listDate"
        :key="item.id"
        class="pagination__item"
      >
        <el-button
          :style="`background-color: ${item.color}; border: 0px solid ${item.color}`"
          class="pagination__btns"
          :disabled="props.listDate.length == 1"
          :id="item.date_for_label_btn"
          @click="currentPage(item.date, item.date_for_label_btn, index)"
        >
          <div class="listDate">
            <span class="listDate__day" :style="`color: ${item.textColor}`">{{
              getDay(item.day)
            }}</span>
            <span class="listDate__date" :style="`color: ${item.textColor}`">{{
              item.date_for_label_btn
            }}</span>
          </div>
        </el-button>
      </div>
    </div>

    <el-button
      class="pagination__nav"
      @click="next(nextDate)"
      :disabled="isDisabledNext"
    >
      <img src="@/assets/icon/chevron-right.svg" alt="next" />
    </el-button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, watch, ref, onMounted } from "vue";
import { langWeb } from "../stores/lang";
import MethodService from "../service/MethodService";
import DataService from "../service/DataService";
const emit = defineEmits([""]);
const isDisabledPrev = ref(false);
const isDisabledNext = ref(false);
const props = defineProps({
  listDate: Array,
  disabledPrev: Boolean,
  disabledNext: Boolean,
});
const prevDate = ref(props.listDate[props.listDate.length - 2]);
const nextDate = ref(props.listDate[1]);
const lang_web = langWeb();
const listDate = ref(MethodService.copyObject(DataService).listDate);
const prev = (date) => {
  emit("prevData", date);
};
const next = (date) => {
  emit("nextData", date);
};
const currentPage = (date, idDate, index) => {
  emit("currentData", date, index);
  document.querySelector(
    `.pagination__item:nth-child(${props.listDate.length}) .pagination__btns`
  ).style.borderBottom = "4px solid transparent";
  Array.from(document.querySelectorAll(".pagination__btns")).forEach(function (
    el
  ) {
    el.classList.remove("active");
  });

  document.getElementById(idDate).classList.add("active");
};
const getDay = (day) => {
  let result = listDate.value.find(
    (item) => day.normalize() === item.value.normalize()
  );
  return result.label
};

watch(
  () => props.disabledPrev,
  (newData) => {
    isDisabledPrev.value = newData;
    let html = document.querySelector(
      `.pagination__item:nth-child(${props.listDate.length}) .pagination__btns`
    );
    if (html) html.style.borderBottom = "4px solid #407BFF";
  }
);
watch(
  () => props.disabledNext,
  (newData) => {
    isDisabledNext.value = newData;
    let html = document.querySelector(
      `.pagination__item:nth-child(${props.listDate.length}) .pagination__btns`
    );
    if (html) html.style.borderBottom = "4px solid #407BFF";
  }
);
watch(
  () => props.listDate,
  (newListDate) => {
    prevDate.value = newListDate[newListDate.length - 2];
    nextDate.value = newListDate[1];
    if (newListDate.length < 7) {
      isDisabledPrev.value = true;
      isDisabledNext.value = true;
    } else {
      isDisabledPrev.value = false;
      isDisabledNext.value = false;
    }
    if (newListDate.length > 1) {
      setTimeout(() => {
        Array.from(document.querySelectorAll(".pagination__btns")).forEach(
          function (el) {
            el.classList.remove("active");
          }
        );
        let html = document.querySelector(
          `.pagination__item:nth-child(${props.listDate.length}) .pagination__btns`
        );
        if (html) html.style.borderBottom = "4px solid #407BFF";
      }, 500);
    }
  }
);
watch(
  () => lang_web.showLang,
  () => {
    listDate.value = MethodService.copyObject(DataService).listDate;
  }
);

onMounted(() => {
  let html = document.querySelector(
    `.pagination__item:nth-child(${props.listDate.length}) .pagination__btns`
  );
  if (html) html.style.borderBottom = "4px solid #407BFF";
});
</script>

<style scoped>
.pagination {
  height: 58px;
  display: flex;
  align-items: center;
  margin: 16px 0 15px 0;
}
.pagination__nav {
  width: fit-content;
  border-radius: 0px;
  padding: 0;
  border: none;
  height: 24px;
}
.pagination__btn {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
}
.pagination__item {
  width: 14.3%;
}
.pagination__item button {
  width: 100%;
  color: #fff;
  border-radius: 0px;
  height: 58px;
}
:deep .el-button > span {
  display: block;
}
:deep .pagination__nav > span {
  height: 24px;
}
:deep .el-button > span > i {
  font-size: 20px;
}
.el-button.pagination__btns.is-disabled,
.el-button.pagination__btns.is-disabled:focus,
.el-button.pagination__btns.is-disabled:hover {
  color: rgb(255, 255, 255) !important;
}

.listDate {
  display: flex;
  flex-direction: column;
}
.listDate__day {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  opacity: 0.75;
  font-family: "Montserrat Medium", "Quicksand", sans-serif;
}
.listDate__date {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.active {
  border-bottom: 4px solid #407bff !important;
}
.pagination__btns:hover {
  border-bottom: 4px solid rgba(64, 123, 255, 128) !important;
}
</style>
